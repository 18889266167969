import React, { useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setUrl } from '../features/url/urlSlice';
import QRCode from 'qrcode.react';
import { BeatLoader } from 'react-spinners';
import { createUser } from '../services/UserServices';
import ReCAPTCHA from 'react-google-recaptcha';

const SharePage = () => {
    const dispatch = useDispatch();
    const recaptchaRef = useRef(null);

    const url = useSelector((state) => state.url.value);
    const [isLoading, setIsLoading] = useState(false);
    const [param, setParam] = useState('');
    const [first_name, setFirstName] = useState('');
    const [last_name, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true);
        const recaptcha = await recaptchaRef.current.executeAsync();
        createUser({
            first_name,
            last_name,
            email,
            recaptcha,
        })
            .then((res) => {
                dispatch(setUrl(window.location.origin + '/donor/' + res.data));
                recaptchaRef.current.reset();
                if (param === 'qrcode') {
                    downloadUniqueQRCode(res.data);
                }
                if (param === 'link') {
                    copyUniqueLink(res.data);
                }
            })
            .catch((error) => {
                recaptchaRef.current.reset();
                alert(error);
            });
    }

    const downloadRef = useRef(null);

    const copyUniqueLink = (uuid) => {
        navigator.clipboard.writeText(window.location.origin + '/donor/' + uuid).then(() => {
            setIsLoading(false);
            alert("Copied! You can share your link to others.");
        });
    }

    const downloadUniqueQRCode = (uuid) => {
        setTimeout(() => {
            const canvas = downloadRef.current.querySelector('canvas');
            const dataURL = canvas.toDataURL('image/png');
            const link = document.createElement('a');
            link.download = 'qrcode.png';
            link.href = dataURL;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            setIsLoading(false);
            alert("Downloaded! You can share your qrcode to others.");
        }, 1000);
    }

    return (
        <>
            {isLoading && (
                <div className="fixed inset-0 z-50 flex items-center justify-center bg-gray-800 bg-opacity-50">
                    <BeatLoader color="#ffffff" loading={true} />
                </div>
            )}
            <h1 className="text-5xl font-light py-11 font-color">Share your love</h1>
            <h3 className="text-2xl pt-1 pb-3 font-medium text-black">
                Thank you for your generous donation. Your support enables Hospice Waikato community nurses to provide support throughout the Waikato community for people living with terminal and life-limiting illnesses.
            </h3>
            <br></br>
            <h3 className="text-2xl pt-1 pb-3 font-light text-black">
                Enter your name and email below to generate a unique QR code or link to share with others and make your way up the leaderboard. Aim to be part of the Keeping our Nurses on the Road Top 5, or support a friend’s campaign.
            </h3>
            <form onSubmit={handleSubmit}>
                <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                    <div className="sm:col-span-3">
                        <label className="block text-sm font-medium leading-6 text-gray-900">First name</label>
                        <div className="mt-2">
                            <input type="text" name="first-name" id="first-name" autoComplete="given-name" onChange={(e) => setFirstName(e.target.value)} required className="px-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-red-800 sm:text-sm sm:leading-6 focus:outline-none" />
                        </div>
                    </div>

                    <div className="sm:col-span-3">
                        <label className="block text-sm font-medium leading-6 text-gray-900">Last name</label>
                        <div className="mt-2">
                            <input type="text" name="family-name" id="family-name" autoComplete="family-name" onChange={(e) => setLastName(e.target.value)} required className="px-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-red-800 sm:text-sm sm:leading-6 focus:outline-none" />
                        </div>
                    </div>

                    <div className="sm:col-span-full">
                        <label className="block text-sm font-medium leading-6 text-gray-900">Email address</label>
                        <div className="mt-2">
                            <input id="email" name="email" type="email" inputMode="email" autoComplete="email" onChange={(e) => setEmail(e.target.value)} required className="px-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-red-800 sm:text-sm sm:leading-6 focus:outline-none" />
                        </div>
                    </div>

                </div>
                <div ref={downloadRef}>
                    <QRCode className='hidden' value={url} size={256} />
                </div>
                <div className="mt-10 border-t border-gray-200 pt-6 flex items-center justify-center gap-x-6">
                    {/* <div className="mt-6 flex items-center gap-x-6 justify-center"> */}
                    <button type="submit" onClick={() => setParam('qrcode')} className="rounded-md button-color px-3 py-2 text-xl font-semibold text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 ">Generate Unique QR code</button>
                    <button type="submit" onClick={() => setParam('link')} className="rounded-md button-color px-3 py-2 text-xl font-semibold text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 md:mr-4">Generate Unique Link</button>
                    {/* </div> */}
                    <ReCAPTCHA
                        size='invisible'
                        style={{ display: 'none' }}
                        sitekey={process.env.REACT_APP_SITE_KEY}
                        ref={recaptchaRef}
                    />
                </div>
            </form>
        </>
    );
};

export default SharePage;