import React from 'react'
import Navbar from './Navbar';
import Footer from './Footer';

const Layout = ({ children }) => {
    return (
        <div className='min-h-screen flex flex-col'>
            <Navbar />
            <div className='h-full w-full bg-gray-50'>
                <div className="mx-auto container border-gray-900/10 pb-8 md:py-14 px-4 md:px-20 lg:px-40 bg-white">
                    {children}
                </div>
            </div>
            <Footer />
        </div>
    );
};

export default Layout