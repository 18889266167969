import React from 'react'

const PaymentSuccessPage = () => {
    return (
        <>
            <div className="flex h-screen items-center mx-4">
                <div className="mx-auto text-3xl">
                    Payment successful! Thank you for your support.”
                </div>
            </div>
        </>
    )
}

export default PaymentSuccessPage