import React, { useEffect, useRef, useState } from 'react'
import { useParams } from 'react-router-dom';
import { BeatLoader } from 'react-spinners';
import { ShieldCheckIcon } from '@heroicons/react/24/outline';
import { getUser } from '../services/UserServices';
import { initStripe } from '../services/StripeService';
import { frontendUrl } from '../env';
import ReCAPTCHA from 'react-google-recaptcha';

const DonorPage = () => {
    const recaptchaRef = useRef(null);

    const { uuid } = useParams();
    const [donor, setDonor] = useState({});
    // const [error, setError] = useState(null);
    const [amount, setAmount] = useState(40);
    const [isLoading, setIsLoading] = useState(false);
    const [isOtherAmount, setIsOtherAmount] = useState(false);

    const handleOptionChange = (amount) => {
        setAmount(amount);
        setIsOtherAmount(false);
    };

    const handleSubmit = async (e) => {
        setIsLoading(true)
        e.preventDefault();
        const recaptcha = await recaptchaRef.current.executeAsync();
        initStripe({
            ...donor, amount,
            success_url: frontendUrl + '/payment/success',
            cancel_url: frontendUrl + '/payment/cancel',
            recaptcha
        }).then((res) => {
            setIsLoading(false)
            recaptchaRef.current.reset();
            window.location.replace(res.data.url);
        }).catch(() => {
            recaptchaRef.current.reset();
            setIsLoading(false);
        });
    }

    useEffect(() => {
        getUser(uuid)
            .then(res => setDonor(res.data))
        // .catch(err => setError(err.message));
    }, [uuid]);

    return (
        <>
            {isLoading && (
                <div className="fixed inset-0 z-50 flex items-center justify-center bg-gray-800 bg-opacity-50">
                    <BeatLoader color="#ffffff" loading={true} />
                </div>
            )}
            <h1 className="text-5xl font-light py-4 sm:py-11 font-color">Donation Form – Keeping our Nurses on the Road</h1>
            <h3 className="text-2xl pb-4 font-medium text-black">
                Hospice Waikato is a free service for people who live in the Waikato and have terminal and life-limiting illnesses. We support our patients and their whanau so they can make the most of the time they have left together.
            </h3>
            <br></br>
            <h3 className="text-2xl pb-4 font-light text-black">
                Please take a few moments to watch this short video and get an insight into a day in the life of hospice care.
            </h3>
            <br></br>
            <div className='flex justify-center'>
                    <div class="w-full md:w-3/4 xl:w-1/2">
                        <iframe width="100%" height="315" src="https://www.youtube.com/embed/1DvS8XptGRE" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                    </div>
                </div>
            <br></br>
            <h3 className="text-2xl pb-4 font-light text-black">
                Your donation will fund the purchase of replacement vehicles to keep our community nurses mobile where they are needed the most.  Thank you for your generous support throughout the Keeping our Nurses on the Road campaign. People like you make our work possible - don’t forget to forward this to 40 friends!
            </h3>
            <h3 className="text-2xl pb-4 text-black mt-4 font-medium">
                Initial donor: {donor.first_name}
            </h3>
            <form onSubmit={handleSubmit}>
                <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                    <div className="sm:col-span-7 lg:col-span-6">
                        <label className="block text-sm font-medium leading-6 text-gray-900">Amount in NZD</label>
                        <div className="flex items-center space-x-4 text-3xl">
                            <button
                                type="button"
                                className={`${amount === 40.00
                                    ? "button-color text-white"
                                    : "bg-white text-gray-400"
                                    } border border-gray-400 text-3xl font-light py-2 px-4 rounded-lg`}
                                onClick={() => handleOptionChange(40.00)}

                            >
                                $40
                            </button>
                            <button
                                type="button"
                                className={`${isOtherAmount
                                    ? "button-color text-white"
                                    : "bg-white text-gray-400"
                                    } border border-gray-400 text-3xl font-light py-2 px-4 rounded-lg`}
                                onClick={() => { setIsOtherAmount(true); setAmount(41.00) }}

                            >
                                Other
                            </button>
                            <div className={`relative ${isOtherAmount ? "visible" : "hidden"}`}>
                                <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-gray-700">$</span>
                                <input
                                    className="pl-7 py-2 px-4 block w-full lg:max-w-[70%] text-right rounded-lg border-0 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-red-800 text-3xl focus:outline-none"
                                    min="40.00" type="number" inputMode="numeric" id="amount" value={amount} onChange={(e) => setAmount(e.target.value)} />
                            </div>
                            {/* <button
                                type="button"
                                className={`${amount === 50.00
                                    ? "button-color text-white"
                                    : "bg-white text-gray-400"
                                    } border border-gray-400 text-3xl font-light py-2 px-4 rounded-lg`}
                                onClick={() => handleOptionChange(50.00)}
                            >
                                $50
                            </button>
                            <button
                                type="button"
                                className={`${amount === 100.00
                                    ? "button-color text-white"
                                    : "bg-white text-gray-400"
                                    } border border-gray-400 text-3xl font-light py-2 px-4 rounded-lg`}
                                onClick={() => handleOptionChange(100.00)}

                            >
                                $100
                            </button> */}
                        </div>
                    </div>
                </div>
                <div className="mt-10 border-t border-gray-200 pt-6 sm:flex sm:items-center sm:justify-between">
                    <p className="mt-4 text-center text-sm text-gray-500 sm:mt-0 sm:text-left">
                        You won't be charged until the next step.
                    </p>
                    <div className="mt-6 flex items-center justify-center md:justify-end gap-x-6">
                        <button id='donate-now' type="submit" className="flex items-center rounded-md button-color px-3 py-2 text-xl font-semibold text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 ">
                            <ShieldCheckIcon className="h-5 w-5 text-white mr-2" />
                            <span>Secure Payment</span>
                        </button>
                        <ReCAPTCHA
                            size='invisible'
                            style={{ display: 'none' }}
                            sitekey={process.env.REACT_APP_SITE_KEY}
                            ref={recaptchaRef}
                        />
                    </div>
                </div>
            </form>
        </>
    )
}

export default DonorPage