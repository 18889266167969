const Footer = () => {
    return (
        <>
            <footer className="bg-color p-2">
                <div className="container mx-auto px-6">
                    <div className="flex justify-center py-4">
                        <div className="mx-auto flex">
                            <div className=" text-white font-bold text-center">
                                &copy; 2023 Hospice Waikato. Powered by <a href="https://www.gds.net.nz/"><u>Global Digital Solutions(GDS)</u></a>. All rights reserved.
                            </div>
                        </div>
                    </div>
                </div>
            </footer >
        </>
    );
};

export default Footer;
