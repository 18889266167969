import React from 'react';

const PaymentCancel = () => {
    return (
        <>
            <div className="flex h-screen items-center mx-4">
                <div className="mx-auto text-3xl">
                    Payment Canceled.
                </div>
            </div>
        </>
    )
}

export default PaymentCancel