import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import DonationPage from './pages/DonationPage';
import LeaderboardPage from './pages/LeaderboardPage';
import SharePage from './pages/SharePage';
import DonorPage from './pages/DonorPage';
import PaymentSuccessPage from './pages/PaymentSuccessPage';
import PaymentCancel from './pages/PaymentCancel';
import { Provider } from 'react-redux'
import store from './store';
import Layout from './layouts/Layout';
import PageNotFoundPage from './pages/PageNotFoundPage';

const App = () => {
  return (
    <Router>
      <Layout>
        <Routes>
          <Route path="/" element={<DonationPage />} />
          <Route path="donation" element={<DonationPage />} />
          <Route path="donor/:uuid" element={<DonorPage />} />
          <Route path="leaderboard" element={<LeaderboardPage />} />
          <Route path="share" element={<SharePage />} />
          <Route path="payment/success" element={<PaymentSuccessPage />} />
          <Route path="payment/cancel" element={<PaymentCancel />} />
          <Route path="*" element={<PageNotFoundPage />} />
        </Routes>
      </Layout>
    </Router>
  );
};

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  </React.StrictMode >
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
