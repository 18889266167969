
import { Disclosure } from '@headlessui/react'
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline'
import { Link } from 'react-router-dom';

const Navbar = () => {

    const logo = require('../logo2_1080x5000c0pcenter.png');

    return (

        <>
            <Disclosure as="nav" className="bg-color shadow z-20">
                {({ open }) => (
                    <>
                        <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
                            <div className="flex flex-shrink-0 h-16 justify-between">
                                <a href="https://www.hospicewaikato.org.nz/">
                                    <div className="flex flex-shrink-0 justify-center items-center bg-white rounded-full h-full sm:w-16 lg:h-32 lg:w-32 lg:border-8	border-gray-200">
                                        <img
                                            className="block h-auto w-16 lg:hidden"
                                            src={logo}
                                            alt="Hispice Waikato"
                                        />
                                        <img
                                            className="hidden w-24 lg:block"
                                            src={logo}
                                            alt="Hispice Waikato"
                                        />
                                    </div>
                                </a>
                                <div className="hidden sm:ml-6 sm:flex sm:space-x-8">
                                    {/* Current: "border-indigo-500 text-gray-900", Default: "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700" */}
                                    <Link to="/donation"
                                        className="inline-flex items-center border-b-2 border-transparent px-1 pt-1 text-base font-medium text-white"
                                    >
                                        Donation
                                    </Link>
                                    <Link to="/leaderboard"
                                        className="inline-flex items-center border-b-2 border-transparent px-1 pt-1 text-base font-medium text-white"
                                    >
                                        Leaderboard
                                    </Link>
                                    <Link to="/share"
                                        className="inline-flex items-center border-b-2 border-transparent px-1 pt-1 text-base font-medium text-white"
                                    >
                                        Share
                                    </Link>
                                </div>
                                <div className="-mr-2 flex items-center sm:hidden">
                                    {/* Mobile menu button */}
                                    <Disclosure.Button className="inline-flex items-center justify-center rounded-md p-2 text-white focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white">
                                        <span className="sr-only">Open main menu</span>
                                        {open ? (
                                            <XMarkIcon className="block h-6 w-6" aria-hidden="true" />
                                        ) : (
                                            <Bars3Icon className="block h-6 w-6" aria-hidden="true" />
                                        )}
                                    </Disclosure.Button>
                                </div>
                            </div>
                        </div>

                        <Disclosure.Panel className="sm:hidden">
                            <div className="space-y-1 pb-3 pt-2">
                                <Link to="/donation"
                                    className="block border-l-4 border-transparent py-2 pl-3 pr-4 text-base font-medium text-white"
                                >
                                    Donation
                                </Link>
                                <Link to="/leaderboard"
                                    className="block border-l-4 border-transparent py-2 pl-3 pr-4 text-base font-medium text-white"
                                >
                                    Leaderboard
                                </Link>
                                <Link to="/share"
                                    className="block border-l-4 border-transparent py-2 pl-3 pr-4 text-base font-medium text-white"
                                >
                                    Share
                                </Link>
                            </div>
                        </Disclosure.Panel>
                    </>
                )}
            </Disclosure>
            {/* <nav className="bg-color">
                <div className="mx-auto px-4">
                    <div className="flex items-center justify-between h-16">
                        <div className="flex items-center">
                            <Link to="/" className="text-gray-300 hover:text-white">
                                Hospice Donation
                            </Link>
                        </div>
                        <div className="flex">
                            <Link to="/donation" className="text-white hover:text-white px-3 py-2 rounded-md text-sm font-medium">
                                Donate
                            </Link>
                            <Link to="/leaderboard" className="text-white hover:text-white px-3 py-2 rounded-md text-sm font-medium">
                                Leaderboard
                            </Link>
                            <Link to="/qrcode" className="text-white hover:text-white px-3 py-2 rounded-md text-sm font-medium">
                                QR Code
                            </Link>
                            <Link to="/links" className="text- hover:text-white px-3 py-2 rounded-md text-sm font-medium">
                                Links
                            </Link>
                        </div>
                    </div>
                </div>
            </nav> */}
        </>
    );
};

export default Navbar;
