import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { backendUrl } from '../env';
import { BeatLoader } from 'react-spinners';

const LeaderboardPage = () => {

    const [donations, setDonations] = useState([]);
    const [loading, setLoading] = useState(false);
    const [progressPercentage, setProgressPercentage] = useState(0);

    useEffect(() => {
        axios.get(backendUrl + '/leaderboard')
            .then(response => {
                setDonations(response.data);
                setLoading(false);
                const sum = response.data.reduce((acc, curr) => acc + parseFloat(curr.total_donations), 0);
                const percent = Math.ceil(sum / 64000 * 100) > 100 ? 100 : Math.ceil(sum / 64000 * 100);
                setProgressPercentage(percent);
            })
            .catch(error => {
                console.log(error.response.data.errors);
                setLoading(false);
            });
    }, []);

    if (loading) {
        return (<div className="fixed inset-0 z-50 flex items-center justify-center bg-gray-800 bg-opacity-50">
            <BeatLoader color="#ffffff" loading={true} />
        </div>);
    }

    return (
        <>
            <h1 className="text-5xl font-light py-4 sm:py-11 font-color">Keeping our Nurses on the Road Leaderboard</h1>
            {/* <h3 className="text-2xl pb-4 font-medium text-black">
                Keeping our Nurses on the Road Leaderboard.
            </h3> */}
            {/* <br></br>
                <h3 className="text-2xl pt-2.5 pb-5 font-light text-black">Generate your unique QR code and link by clicking ‘Share’ above to join the leaderboard. Share these with your family and friends so they can donate on your behalf to bump you up to the #1 spot!</h3> */}
            <div className="min-w-full pb-4 align-middle sm:px-6 lg:px-8">
                <div className='mt-4 flex flex-row leaderboard'>
                    <div className='flex-col w-1/5 h-full pt-10  justify-center items-center'>
                        <div className='text-center'>
                            <div>$64,000</div>
                        </div>
                        <div className='h-5/6 flex justify-center items-center'>
                            <div className="h-full w-6 bg-gray-200 rounded-full dark:bg-gray-700 rotate-180">
                                <div className="bg-color text-xs font-medium text-blue-100 text-center p-0.5 leading-none rounded-full" style={{ height: `${progressPercentage}%` }}></div>
                            </div>
                        </div>
                        <div className='text-center'>$0</div>

                    </div>
                    <div className="flex-col w-4/5">
                        <div className=''>
                            {/* <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                                <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8"> */}
                            <table className="min-w-full divide-y divide-gray-300">
                                <thead>
                                    <tr>
                                        <th scope="col" className="py-3.5 pl-4 pr-3 text-center text-sm font-semibold text-gray-900 sm:pl-0">
                                            Rank
                                        </th>
                                        <th scope="col" className="px-3 py-3.5 text-center text-sm font-semibold text-gray-900">
                                            Donor
                                        </th>
                                        <th scope="col" className="px-3 py-3.5 text-center text-sm font-semibold text-gray-900">
                                            Amount
                                        </th>
                                    </tr>
                                </thead>
                                <tbody className="divide-y divide-gray-200">
                                    {donations.map((donation, index) => (
                                        <tr key={index}>
                                            {/* <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-0">

                                            </td> */}
                                            <td className="whitespace-nowrap px-3 py-4 text-sm text-center font-medium text-gray-900">{index + 1}</td>
                                            <td className="whitespace-nowrap px-3 py-4 text-sm text-center text-gray-500">{donation.first_name}</td>
                                            <td className="whitespace-nowrap px-3 py-4 text-sm text-center text-gray-500">$ {donation.total_donations.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default LeaderboardPage;